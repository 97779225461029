<template >
    <v-container fluid>
        <v-layout>
            <v-flex fluid>
                <v-card outlined>
                    <v-card-title class="px-1">
                        <p class="font-weight-black">
                            Prontuário do Paciente
                        </p>
                        <v-expansion-panels focusable>
                            <v-expansion-panel v-for="(item, i) in 3" :key="i">
                                <v-expansion-panel-header><strong> Nome do Paciente</strong></v-expansion-panel-header>
                                <v-expansion-panel-content>
                                        <v-col >
                                            <v-row >
                                                <v-textarea class="mx-1 mt-1" rows="1" outlined dense value="" readonly label="Data de Nascimento"></v-textarea>
                                                <v-textarea class="mx-1 mt-1" rows="1" outlined dense value="" readonly label="Operadora"></v-textarea>
                                                <v-textarea class="mx-1 mt-1" rows="1" outlined dense value="" readonly label="Modalidade"></v-textarea>
                                                <v-textarea class="mx-1 mt-1" rows="1" outlined dense value="" readonly label="RG"></v-textarea>
                                                <v-textarea class="mx-1 mt-1" rows="1" outlined dense value="" readonly label="CPF"></v-textarea>
                                                <v-textarea class="mx-1 mt-1" rows="1" outlined dense value="" readonly label="CID"></v-textarea>
                                                <v-textarea class="mx-1 mt-1" rows="1" outlined dense value="" readonly label="Descrição do CID"></v-textarea>
                                                <v-textarea class="mx-1 mt-1" rows="1" outlined dense value="" readonly label="Endereço: Rua"></v-textarea>
                                                <v-textarea class="mx-1 mt-1" rows="1" outlined dense value="" readonly label="Nº"></v-textarea>
                                                <v-textarea class="mx-1 mt-1" rows="1" outlined dense value="" readonly label="Bairro"></v-textarea>
                                                <v-textarea class="mx-1 mt-1" rows="1" outlined dense value="" readonly label="Cidade"></v-textarea>
                                                <v-textarea class="mx-1 mt-1" rows="1" outlined dense value="" readonly label="UF"></v-textarea>
                                                <v-textarea class="mx-1 mt-1" rows="1" outlined dense value="" readonly label="Responsável"></v-textarea>
                                                <v-textarea class="mx-1 mt-1" rows="1" outlined dense value="" readonly label="Telefone"></v-textarea>
                                                <v-textarea class="mx-1 mt-1" rows="1" outlined dense value="" readonly label="Alergia"></v-textarea>
                                                <v-textarea class="mx-1 mt-1" rows="1" outlined dense value="" readonly label="Alergia"></v-textarea>
                                                <v-textarea class="mx-1 mt-1" rows="1" outlined dense value="" readonly label="Médico Responsável"></v-textarea>
                                                <v-textarea class="mx-1 mt-1" rows="1" outlined dense value="" readonly label="Geolocalização"></v-textarea>
                                            </v-row>
                                        </v-col>
                                        <v-col>
                                            <v-title-caption><h2>Evoluções</h2></v-title-caption>
                                            <v-card-text>
                                                <v-data-table :headers="headers" :items="desserts" class="elevation-1"></v-data-table>
                                            </v-card-text>
                                        </v-col>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-card-title>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
export default {
    name: "prontuario_paciente",
    data: () => ({
        dialog: false,
        dialogDelete: false,
        files: [],
        headers: [
            { text: 'Profissional', align: 'start', sortable: true, value: '' },
            { text: 'Especialidade', align: 'start', sortable: true, value: '' },
            { text: 'Procedimento', align: 'start', sortable: true, value: '' },
            { text: 'Data da Evolução', align: 'start', sortable: true, value: '' },
            { text: 'Visualizar', align: 'start', sortable: true, value: '' },
            
        ],
    })
}
</script>
